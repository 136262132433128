<template>
  <multiselect :class="classes" v-bind="attributes" v-model="model.value">
    <template slot="noResult">No results found.</template>
  </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect/src/Multiselect'

export default {
  name: 'FormSelect',
  components: {
    Multiselect
  },
  props: {
    model: { type: Object, default: undefined },
    name: { type: String, required: true },
    // value: { type: Array, default: undefined },
    options: { type: Array, required: true },
    label: { type: String, default: 'name' },
    trackBy: { type: String, default: 'id' },
    placeholder: { type: String, default: undefined },
    tagPlaceholder: { type: String, default: undefined },
    closeOnSelect: { type: Boolean, default: undefined },
    hideSelected: { type: Boolean, default: false },
    searchable: { type: Boolean, default: true },
    multiple: { type: Boolean, default: false },
    taggable: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    showLabels: { type: Boolean, default: false },
    customLabel: { type: Function, default: undefined },
    max: { type: Number, default: undefined },
    optionHeight: { type: Number, default: 34 }
  },
  computed: {
    classes () {
      const classes = ['multiselect-emp']

      if (this.disabled) classes.push('disabled')

      return classes.join(' ')
    },
    attributes () {
      return {
        id: this.name,
        name: this.name,
        options: this.options,
        label: this.label,
        trackBy: this.trackBy,
        placeholder: this.placeholder,
        tagPlaceholder: this.tagPlaceholder,
        closeOnSelect: this.closeOnSelectValue,
        hideSelected: this.hideSelected,
        searchable: this.searchable,
        multiple: this.multiple,
        taggable: this.taggable,
        disabled: this.disabled,
        allowEmpty: this.disabled || !this.required,
        loading: this.loading,
        showLabels: this.showLabels,
        customLabel: this.customLabel,
        max: this.max,
        optionHeight: this.optionHeight
      }
    },
    closeOnSelectValue () { return typeof this.closeOnSelect === 'undefined' ? !this.multiple : this.closeOnSelect }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
